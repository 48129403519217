@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
    font-weight: bold;
    margin-top: 1rem;
    margin-left: 0.2rem;
}

input {
    margin-left: 1rem;
}

body {
    align-items: center;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    text-align: center;
}

/*========== GOOGLE FONTS ==========*/

/*========== VARIABLES CSS ==========*/
:root {
  --header-height: 3.5rem;
  --nav-width: 219px;

  /*========== Colors ==========*/
  --first-color: #6923D0;
  --first-color-light: #F4F0FA;
  --title-color: #19181B;
  --text-color: #58555E;
  --text-color-light: #A5A1AA;
  --body-color: #F9F6FD;
  --container-color: #FFFFFF;

  /*========== Font and typography ==========*/
  --body-font: 'Poppins', sans-serif;
  --normal-font-size: .938rem;
  --small-font-size: .75rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-fixed: 100;
}

@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*========== BASE ==========*/
*, ::before, ::after {
  box-sizing: border-box;
}

body {
  /* margin: 3rem 0 0 0; */
  /* padding: 1rem 1rem 0; */
  font-family: 'Poppins', sans-serif;
  font-family: var(--body-font);
  font-size: .938rem;
  font-size: var(--normal-font-size);
  background-color: #F9F6FD;
  background-color: var(--body-color);
  color: #58555E;
  color: var(--text-color);
}

h3 {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*========== HEADER ==========*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  background-color: var(--container-color);
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  padding: 0 1rem;
  z-index: 100;
  z-index: var(--z-fixed);
}

.header__container {
  display: flex;
  align-items: center;
  height: 3.5rem;
  height: var(--header-height);
  justify-content: space-between;
}

.header__logo {
  color: #19181B;
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
  /* display: none; */
}



@media screen and (min-width: 768px) {
  /* body {
    padding: 1rem 3rem 0 6rem;
  } */
  .header {
    padding: 0 3rem 0 6rem;
  }
  .header__container {
    height: calc(3.5rem + .5rem);
    height: calc(var(--header-height) + .5rem);
  }

  .header__logo {
    display: block;
  }


}

